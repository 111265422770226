body {
  background: #333;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
}

* {
  color: white;
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: white;
  margin: 0;
  padding: 0;
}

ul, li {
  color: inherit;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: red !important;
}

::-webkit-scrollbar-track {
  background: white !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.checkbox-custom {
  .ant-checkbox-inner {
    background: transparent !important;
    border-radius: 0 !important;
    &:hover {
      border-color: white !important;
      background-color: white;
    }
    &.ant-checkbox-checked {
      border-color: red !important;
    }
  }
  :where(.css-dev-only-do-not-override-1okl62o).ant-checkbox-checked .ant-checkbox-inner {
    border-color: red !important;
  }
}